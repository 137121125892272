/*
## Authentification

- La plupart des requêtes à l'API doivent être authentifiées (token dans authorization-header)
- Si un userToken est en localStorage : requête de connexion de l'utilisateur (LOGIN : vérif. token et récupération des infos)
- Sinon : Requête avec login/password (AUTH: vérif. identifiants, retourne un token) pour la connexion de l'utilisateur.

## Notes

	- DISPATCH : actions (invoquées hors ou depuis le store)
	- COMMIT : mutations (uniquement invoquées par le store)
	
	Les mutations mettent à jour l'état de l'application (`state`).

## LocalStorage

	Certaines données sont enregistrées localement : App fait un `subscribe` sur les mutations du Store et lance l'action (`dispatch`) `STORAGE_SYNC`
	Lorsque les mutations observées sont appelées (`commit`) les valeurs du `state` sont enregistrées (userToken, etc.)
	De cette manière le localStorage est uniquement modifié par l'action STORAGE_SYNC, lui même invoqué par des mutations.

	Certaines données du state (ex. utilisateur) sont chargées automatiquement depuis le locatStorage.

*/

import Vue from "vue";

// Store
import Vuex from "vuex";
Vue.use(Vuex);

// Axios
import { HTTP } from "./http-common.js";

// LocalStorage
// This will register the instance `Vue.$localStorage`
import Storage from "vue-web-storage";
Vue.use(Storage);

// Helpers
import { get } from "./libs/helpers.js";

// Store
export default new Vuex.Store({
  state: {
    // Authentication
    userToken: Vue.$localStorage.get("userToken", null),
    userData: Vue.$localStorage.get("userData", null),

    // Dashboard
    dashboardFilters: Vue.$localStorage.get("dashboardFilters", null),

    // Local question draft
    questionDraft: Vue.$localStorage.get("questionDraft", null),

    // User IP from France?
    isFromFrance: Vue.$localStorage.get("isFromFrance", null),
  },

  // MUTATIONS
  // L'App root surveille les mutation avec subscribe.
  mutations: {
    // Save user IP country resolution
    FROM_FRANCE: (state, isFromFrance) => {
      state.isFromFrance = isFromFrance;
    },

    STORE_DASHBOARD_FILTERS: (state, payload) => {
      state.dashboardFilters = payload;
    },

    RESET_QUESTION_DRAFT: (state) => {
      state.questionDraft = null;
    },

    // Update the local question data
    // dataFragment can be questionDraft.themes, questionDraft.details, etc.
    SAVE_QUESTION_DRAFT: (state, payload) => {
      let questionID = parseInt(payload.questionID) || 0;
      state.questionDraft = {
        ...state.questionDraft,
        ...payload.dataFragment,
        questionID: questionID,
      };
    },

    // Update state with new user data
    UPDATE_USER_DATA: (state, userData) => {
      state.userData = userData;
    },

    // Retrieve authentified user data
    USER_LOGIN: (state, userData) => {
      state.userData = userData;
    },
    USER_LOGOUT: (state, userData) => {
      state.userToken = null;
      state.userData = null;
      state.questionDraft = null;
      state.isFromFrance = null;
    },

    // Update user token (Authorization Header)
    AUTH_SUCCESS: (state, userToken) => {
      state.userToken = userToken;
    },
    AUTH_LOGOUT: (state) => {
      state.userToken = "";
      state.userData = "";
    },
  },

  actions: {
    // Check if user IP is from France
    FROM_FRANCE: ({ commit, dispatch }) => {
      return new Promise((resolve, reject) => {
        HTTP.get("/is_from_france")
          .then(function(response) {
            const payload = response.data.payload;
            commit("FROM_FRANCE", payload);
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    /**
     * Stripe Create Customer Portal Session
     *
     * @param String Validation JWT
     * @returns
     */
    STRIPE_CUSTOMER_PORTAL_SESSION: ({ commit, dispatch }, payload) => {
      return new Promise((resolve, reject) => {
        HTTP.post("/subscriptions/customer_portal_session", payload)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    /**
     * Stripe Create Checkout Session
     *
     */
    STRIPE_CHECKOUT_SESSION: ({ commit, dispatch }, payload) => {
      return new Promise((resolve, reject) => {
        HTTP.post("/subscriptions/checkout_session", payload)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    /**
     * Confirm subscription to the newsletter
     *
     * @param String Validation JWT
     * @returns
     */
    NEWSLETTER_CONFIRM_SUBSCRIPTION: ({ commit, dispatch }, { validationToken }) => {
      return new Promise((resolve, reject) => {
        HTTP.post(`/newsletter/confirm-subscription/${validationToken}`)
          .then(function(response) {
            const result = response.data.payload || null;
            resolve(result);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    /**
     * Confirm unsubscription from the newsletter
     *
     * @param String Validation JWT
     * @returns
     */
    NEWSLETTER_CONFIRM_UNSUBSCRIPTION: ({ commit, dispatch }, { validationToken }) => {
      return new Promise((resolve, reject) => {
        HTTP.post(`/newsletter/confirm-unsubscription/${validationToken}`)
          .then(function(response) {
            const result = response.data.payload || null;
            resolve(result);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Assign expert (userID) to question (questionID)
    POST_ASSIGN_EXPERT: ({ commit, dispatch }, { questionID, userID }) => {
      return new Promise((resolve, reject) => {
        HTTP.put(`questions/${questionID}/expert`, { expert_id: userID })
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Get all expert users
    GET_EXPERT_USERS: ({ commit, dispatch }) => {
      return new Promise((resolve, reject) => {
        HTTP.get("experts")
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Update question details
    PUT_ANSWER_DETAILS: ({ commit, dispatch }, { questionID, payload }) => {
      return new Promise((resolve, reject) => {
        HTTP.put(`questions/${questionID}/details`, payload)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Post answer to question
    POST_ANSWER: ({ commit, dispatch }, { questionID, payload }) => {
      return new Promise((resolve, reject) => {
        HTTP.post(`questions/${questionID}/answers`, payload)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Fetch question feedback
    GET_FEEDBACK: ({ commit, dispatch }, questionID) => {
      return new Promise((resolve, reject) => {
        HTTP.get(`questions/${questionID}/feedback`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // User Feedback
    SEND_FEEDBACK: ({ commit, dispatch }, payload) => {
      return new Promise((resolve, reject) => {
        HTTP.post("feedback", payload)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Remember dashboard filters
    STORE_DASHBOARD_FILTERS: ({ commit, dispatch }, payload) => {
      commit("STORE_DASHBOARD_FILTERS", payload);
    },

    // Delete question ("draft" status only)
    DELETE_QUESTION: ({ commit, dispatch }, questionID) => {
      return new Promise((resolve, reject) => {
        HTTP.delete(`questions/${questionID}`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // New question: save local question draft
    RESET_QUESTION_DRAFT: ({ commit, dispatch }) => {
      commit("RESET_QUESTION_DRAFT");
    },

    // Edit question: save local question draft
    SAVE_QUESTION_DRAFT: ({ commit, dispatch }, payload) => {
      commit("SAVE_QUESTION_DRAFT", payload);
    },

    // Set question status to "publish"
    COMPLETE_QUESTION: ({ commit, dispatch }, questionID) => {
      return new Promise((resolve, reject) => {
        HTTP.post(`questions/${questionID}/complete`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Set question status to "publish"
    PUBLISH_QUESTION: ({ commit, dispatch }, questionID) => {
      return new Promise((resolve, reject) => {
        HTTP.post(`questions/${questionID}/publish`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Insert/Update a question (from a question draft)
    // TODO: drafts dataFragment?
    SAVE_QUESTION: ({ state, commit, dispatch }) => {
      let data = state.questionDraft;
      let questionID = parseInt(data.questionID);
      let req = null;

      // Insert/Update question
      req = new Promise((resolve, reject) => {
        HTTP.post(`questions/${questionID}`, data)
          .then(function(response) {
            const payload = response.data.payload || null;
            const questionID = payload?.questionID || null;
            // Update local draft
            commit("SAVE_QUESTION_DRAFT", {
              dataFragment: {},
              questionID: questionID,
            });
            resolve(questionID);
          })
          .catch(function(error) {
            reject(error);
          });
      });
      return req;
    },

    // Récupération des thématiques
    GET_THEMES: ({ commit, dispatch }) => {
      return new Promise((resolve, reject) => {
        HTTP.get("themes")
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Delete question reference
    DELETE_DOCUMENT: ({ commit, dispatch }, hash) => {
      return new Promise((resolve, reject) => {
        HTTP.delete("files", { data: hash })
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Récupération d'une question
    GET_QUESTION_DATA: ({ commit, dispatch }, questionID) => {
      return new Promise((resolve, reject) => {
        HTTP.get(`questions/${questionID}`)
          .then(function(response) {
            const payload = response?.data?.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Récupération des réponses
    GET_ANSWERS: ({ commit, dispatch }, questionID) => {
      return new Promise((resolve, reject) => {
        HTTP.get(`questions/${questionID}/answers`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Récupération des questions
    // NOTE: les infos d'identification de l'utilisateur se trouvent dans le JWT envoyé avec chaque requête.
    // On récupère donc uniquement les questions qui concernent l'utilisateur connecté, en fonction des ses permissions.
    GET_QUESTIONS: ({ commit, dispatch }) => {
      return new Promise((resolve, reject) => {
        HTTP.get("questions")
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Authentification
    AUTH_REQUEST({ commit, dispatch }, loginData) {
      return new Promise((resolve, reject) => {
        // API call to authenticate user
        HTTP.post("sign-in", loginData)
          .then(function(response) {
            const payload = response.data ? response.data.payload || null : null;
            const userToken = payload?.userToken || null;
            if (userToken === null) {
              dispatch("AUTH_LOGOUT");
              reject("userToken is null");
            } else {
              commit("AUTH_SUCCESS", userToken); // update status (mutation)
              resolve(response);
            }
          })
          .catch(function(error) {
            dispatch("AUTH_LOGOUT");
            console.info("Authentification error", error);
            reject("Authentification error", error);
          });
      });
    },

    // Dés-authentification
    AUTH_LOGOUT: ({ commit, dispatch }) => {
      return new Promise((resolve, reject) => {
        commit("AUTH_LOGOUT");
        resolve();
      });
    },

    // Connexion de l'utilisateur authentifié (le JWT est passé automatiquement dans les en-têtes de chaque requête)
    USER_LOGIN: ({ commit, dispatch }) => {
      return new Promise((resolve, reject) => {
        // API call to login user
        HTTP.post("login")
          .then(function(response) {
            const userData = response?.data.payload || null;

            if (userData === null) {
              console.error("Unexpected error", "userData is null");
              dispatch("AUTH_LOGOUT");
              reject("userData is null");
            } else {
              // update status (mutation)
              commit("USER_LOGIN", userData);
              resolve(response);
            }
          })
          .catch(function(err) {
            // login error
            dispatch("AUTH_LOGOUT");
            reject(err);
          });
      });
    },

    // Déconnexion et désauthentification de l'utilisateur
    USER_LOGOUT: ({ commit, dispatch, state }) => {
      return new Promise((resolve, reject) => {
        // Unvalidate tokens
        HTTP.post("logout")
          .then(function(response) {
            const payload = response.data.payload || null;
          })
          .catch(function(error) {
            reject(error);
          });

        // Update State and Storage
        commit("USER_LOGOUT");
        resolve();
      });
    },

    // Reset password
    RESET_PASSWORD: ({ commit, dispatch }, payload) => {
      return new Promise((resolve, reject) => {
        HTTP.post("reset-password", payload)
          .then(function(response) {
            const result = response.data.payload || null;
            resolve(result);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Request a password reset (send a password reset token by email)
    REQUEST_PASSWORD_RESET: ({ commit, dispatch }, email) => {
      return new Promise((resolve, reject) => {
        HTTP.post("request-password-reset", { email: email })
          .then(function(response) {
            const result = response.data.payload || null;
            resolve(result);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Requestion new validation email
    NEW_VALIDATION_EMAIL: ({ commit, dispatch, state }, email) => {
      return new Promise((resolve, reject) => {
        HTTP.post("users/validate", { email: email })
          .then(function(response) {
            const result = response.data.payload || null;
            resolve(result);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Check email validation token
    USER_VALIDATE: ({ commit, dispatch, state }, { userID, validationToken }) => {
      return new Promise((resolve, reject) => {
        HTTP.post(`users/${userID}/validate/${validationToken}`)
          .then(function(response) {
            const result = response.data.payload || null;
            resolve(result);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Request email update
    REQUEST_EMAIL_UPDATE: ({ commit, dispatch }, { payload, userID }) => {
      return new Promise((resolve, reject) => {
        HTTP.post(`users/${userID}/request-email-update`, payload)
          .then(function(response) {
            const result = response.data.payload || null;
            resolve(result);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Update email
    UPDATE_EMAIL: ({ commit, dispatch }, { token, userID }) => {
      return new Promise((resolve, reject) => {
        HTTP.post(`users/${userID}/update-email`, { token: token })
          .then(function(response) {
            const result = response.data.payload || null;
            resolve(result);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Update password
    UPDATE_PASSWORD: ({ commit, dispatch }, { payload, userID }) => {
      return new Promise((resolve, reject) => {
        HTTP.put(`users/${userID}/update-password`, payload)
          .then(function(response) {
            const result = response.data.payload || null;
            resolve(result);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Profile: update user
    UPDATE_USER_DATA: ({ commit, dispatch, state }, { userID, formData }) => {
      return new Promise((resolve, reject) => {
        // NOTE: The server (o2switch) does not support send multipart/form-data with PUT method (payload is empty)
        // Spoof "PUT" method to send multipart/form-data
        // https://stackoverflow.com/questions/54686218/laravel-vuejs-axios-put-request-formdata-is-empty
        // formData.append("_method", "put");

        HTTP.post(`users/${userID}/update-profile`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then(function(response) {
            let userData = response.data.payload || null;
            if (userData) {
              commit("UPDATE_USER_DATA", userData);
              resolve(userData);
            }
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Registration: create user
    INSERT_USER_DATA: ({ commit, dispatch, state }, { userID, formData }) => {
      return new Promise((resolve, reject) => {
        HTTP.post(`users/${userID}/profile`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then(function(response) {
            let userData = response.data.payload || null;
            // User profile created -> login user
            commit("USER_LOGIN", userData);
            resolve(response);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Registration: create user
    CREATE_USER: ({ commit, dispatch, state }, payload) => {
      return new Promise((resolve, reject) => {
        HTTP.post("users", payload)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    /**
     * Refresh Current User Data
     *
     * @param Int userID
     */
    REFRESH_USER: ({ commit, dispatch }, userID) => {
      return new Promise((resolve, reject) => {
        // load data
        dispatch("GET_USER_DATA", userID).then((response) => {
          // then commit UPDATE_USER_DATA to refresh state and localStorage
          commit("UPDATE_USER_DATA", response);
          // And return fresh user data
          resolve(response);
        });
      });
    },

    // Profile: get user data
    GET_USER_DATA: ({ commit, dispatch }, userID) => {
      return new Promise((resolve, reject) => {
        HTTP.get(`users/${userID}/profile`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // localStorage Sync
    // L'App root subscribe aux mutations du Store pour mettre en cache les données de certaines mutations.
    STORAGE_SYNC: ({ commit, state }, mutation) => {
      return new Promise((resolve, reject) => {
        console.log("STORAGE_SYNC", mutation, state);

        if (mutation.type === "FROM_FRANCE") {
          Vue.$localStorage.set("isFromFrance", state.isFromFrance);
          resolve(mutation);
        }

        if (mutation.type === "STORE_DASHBOARD_FILTERS") {
          Vue.$localStorage.set("dashboardFilters", state.dashboardFilters);
          resolve(mutation);
        }

        if (mutation.type === "RESET_QUESTION_DRAFT") {
          Vue.$localStorage.remove("questionDraft");
          resolve(mutation);
        }

        if (mutation.type === "SAVE_QUESTION_DRAFT") {
          Vue.$localStorage.set("questionDraft", state.questionDraft);
          resolve(mutation);
        }

        // Authentification réussie, on enregistre le userToken
        if (mutation.type === "AUTH_SUCCESS") {
          Vue.$localStorage.set("userToken", state.userToken);
          resolve(mutation);
        }

        // Erreur d'authentification ou Logout on supprime le userToken
        if (mutation.type === "AUTH_LOGOUT") {
          Vue.$localStorage.remove("userToken");
          Vue.$localStorage.remove("userData");
          resolve(mutation);
        }

        // Connexion de l'utilisateur : on enregistre ses données
        if (mutation.type === "USER_LOGIN" || mutation.type === "UPDATE_USER_DATA") {
          console.log("UPDATE_USER_DATA mutation");
          Vue.$localStorage.set("userData", state.userData);
          resolve(mutation);
        }

        // Erreur de login ou Logout : on supprime les données de l'utilisateur
        if (mutation.type === "USER_LOGOUT") {
          console.log("USER_LOGOUT mutation");

          Vue.$localStorage.clear(true);
          resolve(mutation);
        }

        // Si erreur : reject (mutation);
      });
    },
  },
  modules: {},
});
