<template>
	<button
		type="button"
		class="button"
		@click="handleClick()"
	>
		<slot>Logout</slot>
	</button>
</template>

<script>
export default {
	name: "buttonLogout",
	methods: {
		logoutUser: function(event) {
			this.$store
				.dispatch("USER_LOGOUT")
				.then(() => this.$router.push("/login"));
		},
		handleClick: function(event) {
			this.logoutUser();
			this.$emit("click");
		}
	}
};
</script>
