<template>
	<div class="help-wrapper">
		<div class="helper" :class="seenOnce ? 'seen-once' : ''">
			<button type="button" class="button--cta" @click="displayHelp">
				<svg
					class="icon icon-info"
					role="img"
					xmlns="http://www.w3.org/2000/svg"
					width="22"
					height="22"
					fill="none"
					viewBox="0 0 22 22"
				>
					<path
						fill="currentColor"
						fill-rule="evenodd"
						d="M11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2ZM0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11Z"
						clip-rule="evenodd"
					/>
					<path
						fill="currentColor"
						fill-rule="evenodd"
						d="M11 10C11.5523 10 12 10.4477 12 11V15C12 15.5523 11.5523 16 11 16C10.4477 16 10 15.5523 10 15V11C10 10.4477 10.4477 10 11 10Z"
						clip-rule="evenodd"
					/>
					<path
						fill="currentColor"
						d="M12 7C12 7.55228 11.5523 8 11 8C10.4477 8 10 7.55228 10 7C10 6.44772 10.4477 6 11 6C11.5523 6 12 6.44772 12 7Z"
					/></svg
				><span class="caption">Besoin d’aide&nbsp;?</span>
			</button>
		</div>
	</div>
</template>

<style lang="scss">
.helper {
	position: fixed;
	right: 1rem;
	bottom: 1rem;
	transition: transform ease-out 0.3s;

	&.seen-once {
		transform: translateX(calc(100% - 1.3em));
		&:focus,
		&:hover {
			transform: translateX(0);
		}
	}

	.button--cta {
		font-size: 1rem;
		padding: 0.5em;
		.icon {
			height: 1.25em;
		}
		animation-delay: 3s !important;
		animation: slide-in-right 0.5s cubic-bezier(0.23, 1, 0.32, 1) both;
	}
}

@media screen and (max-width: 768px) {
	.helper {
		transform: translateX(calc(100% - 1.3em)) !important;
	}
}

.toasted.help-message .toasted {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-around;
	justify-content: space-evenly;
}
</style>

<script>
export default {
	data: function() {
		return {
			isHelpModalOpen: false,
			seenOnce: false,
		};
	},

	methods: {
		mailTo: function(email, subject = "") {
			if (subject) subject = "?subject=" + encodeURIComponent(subject);
			window.location.href = `mailto:${email}${subject}`;
			// Target blank: OK for webmails but leaves a blank tab for native clients.
			//  return window.open(`mailto:${email}${subject}`, "_blank");
		},

		displayHelp: function() {
			this.seenOnce = true;
			this.$toasted.clear();

			this.$toasted.show(
				'<div class="message-wrapper">Si vous rencontrez le moindre souci pour utiliser notre plateforme, n’hésitez pas à contacter notre support. Nous nous ferons un plaisir de vous accompagner.</div>',
				{
					containerClass: "toasted help-message",
					theme: "primary",
					type: "help",
					position: "top-center",
					duration: null,
					closeOnSwipe: false,
					action: [
						{
							text: "Contacter support@crossdoc.fr",
							onClick: (e, toastObject) => {
								this.mailTo("support@crossdoc.fr", "Demande d’assistance");
							},
						},
						{
							text: "Fermer",
							onClick: (e, toastObject) => {
								toastObject.goAway(0);
							},
						},
					],
				}
			);
		},
	},
};
</script>
