// Application config
/*
	Env varibales from .env.production / .env.development
	Replaced by static vars at build time
	https://cli.vuejs.org/guide/mode-and-env.html#using-env-variables-in-client-side-code
*/

const appConfig = {
  API_BASE_URL: process.env.VUE_APP_API_BASE_URL,
  STRIPE_PUBLIC_API_KEY: process.env.VUE_APP_STRIPE_PUBLIC_API_KEY,
  trackingID: "UA-37899854-55",
  eventTracking: process.env.NODE_ENV !== "development",
};

if (process.env.NODE_ENV === "development") {
  console.info("process.env", process.env);
  console.info("appConfig", appConfig);
}

export { appConfig };
