<template>
  <header class="app-header">
    <div class="site-wrapper flex-row flex-center-v flex-gap">
      <div class="flex-row flex-center flex-align-bottom flex-gap logo-wrapper">
        <a href="https://www.crossdoc.fr/">
          <img class="app-logo" src="@/assets/svg/CrossDoc-logo.svg" alt="CrossDoc" />
        </a>

        <div class="flex-col flex-gap partnership" v-if="displayNestlePartnership">
          <p><em>En partenariat avec</em></p>
          <img src="/logos/logo-guigoz.svg" alt="Logo Guigoz" />
        </div>
      </div>

      <nav class="navigation-primary flex-row flex-align-right">
        <a class="button" href="https://crossdoc.fr/" v-if="!isAuthenticated">Accueil</a>
        <router-link to="/dashboard" class="button" v-if="isAuthenticated && !user_is_coordinator">Mes questions</router-link>
        <router-link to="/dashboard" class="button" v-if="isAuthenticated && user_is_coordinator">Questions</router-link>
        <router-link to="/" class="button" v-if="!isRegistrationPending && !isAuthenticated">Inscription</router-link>
        <router-link :to="`/user/${user.id}/profile`" class="button" v-if="isAuthenticated">Mon compte</router-link>
        <button-logout v-if="!!this.$store.state.userToken">Déconnexion</button-logout>
        <router-link to="/login" class="button--cta" v-if="!isAuthenticated">Connexion</router-link>
      </nav>

      <div class="user-card" v-if="isAuthenticated">
        <div>
          <header>
            <router-link :to="`/user/${user.id}/profile`">{{ user.firstname }} {{ user.lastname }}</router-link>
          </header>
        </div>
        <picture class="avatar">
          <img role="img" :src="user.avatar" :alt="`${user.firstname[0]}${user.lastname[0]}`" width="60" height="60" />
        </picture>
      </div>
    </div>
  </header>
</template>

<script>
import { HTTP } from "../http-common.js";
import buttonLogout from "@/components/buttonLogout";

export default {
  name: "AppHeader",
  components: { buttonLogout },

  data: function() {
    return {
      API_URL: HTTP.defaults.baseURL,
    };
  },

  computed: {
    displayNestlePartnership() {
      return this.user?.specialty === "Sage-femme";
    },

    user: function() {
      return this.$store.state.userData;
    },

    // Is the user a coordinator? (role 3)
    user_is_coordinator: function() {
      return this.user.roles.indexOf(3) !== -1;
    },

    // Is the user an expert? (role 2)
    user_is_expert: function() {
      return this.user.roles.indexOf(2) !== -1;
    },

    // Utilisateur connecté mais inscription incomplete
    isRegistrationPending: function() {
      return !!this.$store.state.userToken && this.$store.state.userData && !this.$store.state.userData.registration_complete;
    },

    // Utilisateur connecté ?
    isAuthenticated: function() {
      if (!!this.$store.state.userToken && !!this.$store.state.userData && this.$store.state.userData.registration_complete) return true;

      return false;
    },
  },
};
</script>

<style lang="scss">
@import "src/scss/01-settings/settings.colors";
@import "src/scss/01-settings/settings.typography";
@import "src/scss/01-settings/settings.variables";
@import "src/scss/02-tools/tools.mixins";

@import "src/scss/06-components/components.header";
@import "src/scss/06-components/components.navigation";
@import "src/scss/06-components/components.button";
@import "src/scss/06-components/components.user-card";

.user-card,
.navigation-primary {
  margin-top: 1rem;
}

.navigation-primary {
  & + .user-card {
    margin-left: 2rem;
  }
}
</style>
