<template>
	<footer class="app-footer">
		<help />
	</footer>
</template>

<script>
import Help from "@/components/Help.vue";

export default {
	name: "AppFooter",
	components: {
		Help
	},
};
</script>
