// https://gitlab.com/anamorphik/tools/snippets/-/snippets/2105208

/**
 * Format Unix Timestamp to ISO (2021-03-10T13:49:05.000Z)
 * @param {Interger/String} timestamp
 */
const timestamp_format_iso = (timestamp) => {
  const d = new Date(parseInt(timestamp) * 1000);
  return new Date(
    d.getTime() - d.getTimezoneOffset() * 60 * 1000
  ).toISOString();
};

/**
 * Format Unix Timestamp to YMD (yyyy-mm-dd)
 * @param {Interger/String} timestamp
 */
const timestamp_format_YMD = (timestamp) => {
  const d = new Date(parseInt(timestamp) * 1000);
  return new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000)
    .toISOString()
    .split("T")[0];
};

/**
 * Format Unix Timestamp to human readable date (ex. 10/03/2021 à 13:49:05)
 * @param {Interger/String} timestamp
 */
const timestamp_format_human = (timestamp, options = {}) => {
  var defaults = {
    timeZone: "Europe/Paris",
    // weekday: "short",
    // year: "numeric",
    // month: "2-digit",
    // day: "numeric",
  };
  options = { ...defaults, ...options };
  const d = new Date(parseInt(timestamp) * 1000);
  return d.toLocaleString("fr-FR", options);
};

// Gets the value at path of object.
// https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_get
const get = (obj, path, defaultValue) => {
  const travel = (regexp) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce(
        (res, key) => (res !== null && res !== undefined ? res[key] : res),
        obj
      );
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  return result === undefined || result === obj ? defaultValue : result;
};

// Create the slug of a string
const slugify = (value) => {
  value = value.replace(/^\s+|\s+$/g, ""); // trim
  value = value.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
  var to = "aaaaaeeeeeiiiiooooouuuunc------";
  for (var i = 0, l = from.length; i < l; i++) {
    value = value.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  value = value
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return value;
};

const replaceDiacritics = (str) => {
  let diacritics = [
    { char: "A", base: /[\300-\306]/g },
    { char: "a", base: /[\340-\346]/g },
    { char: "E", base: /[\310-\313]/g },
    { char: "e", base: /[\350-\353]/g },
    { char: "I", base: /[\314-\317]/g },
    { char: "i", base: /[\354-\357]/g },
    { char: "O", base: /[\322-\330]/g },
    { char: "o", base: /[\362-\370]/g },
    { char: "U", base: /[\331-\334]/g },
    { char: "u", base: /[\371-\374]/g },
    { char: "N", base: /[\321]/g },
    { char: "n", base: /[\361]/g },
    { char: "C", base: /[\307]/g },
    { char: "c", base: /[\347]/g },
  ];

  diacritics.forEach(function(letter) {
    str = str.replace(letter.base, letter.char);
  });

  return str;
};

/**
 * Check Valid Email
 * @param {string} email
 * @returns
 */
const emailIsValid = function(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

/**
 * Give Some Funk
 */
export {
  replaceDiacritics,
  get,
  slugify,
  emailIsValid,
  timestamp_format_human,
  timestamp_format_iso,
  timestamp_format_YMD,
};
