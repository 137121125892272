<template>
	<div id="outdated">
		<div class="inner-wrapper">
			<h6>Votre navigateur est obsolète&nbsp;!</h6>
			<p>
				<strong
					>Votre navigateur n'est plus maintenu par son éditeur et vous expose à des problèmes de sécurité.</strong
				>
			</p>
			<p>
				<a class="button--cta" id="btnUpdateBrowser" href="http://outdatedbrowser.com/fr">Mettre à jour maintenant</a>
			</p>
			<div id="btnCloseUpdateBrowser"></div>
			<div></div>
		</div>
		<div></div>
	</div>
</template>

<style lang="scss" scoped>
@import "~@/scss/06-components/components.outdatedbrowser";
</style>

<script>
export default {
	name: "outdatedBrowserInfo",

	data: function() {
		return {
			debug: false
		};
	},

	methods: {
		checkBrowser: function() {
			// https://github.com/outdatedbrowser/outdated-browser/issues/198
			var supportsPromise =
				typeof Promise !== "undefined" && Object.prototype.toString.call(Promise.resolve()) === "[object Promise]";

			if (this.debug || false === supportsPromise) {
				outdatedBrowser({
					// bgColor: "white",
					// color: "#4a4a4a",
					lowerThan: true, // overriden by supportsPromise
					languagePath: "" // message in <template>
				});
			}
		}
	},

	mounted() {
		let self = this;
		window.addEventListener("load", function(event) {
			self.checkBrowser();
		});
	}
};
</script>
