import Vue from "vue";
import VueRouter from "vue-router";
import { NProgress } from "./nprogress.js";

const Subscription = () =>
  import(/* webpackChunkName: "Subscription" */ "./views/Subscription.vue");

// import Home from "./views/Home.vue";
const Home = () => import(/* webpackChunkName: "Home" */ "./views/Home.vue");

// import Login from "./views/Login.vue";
const Login = () => import(/* webpackChunkName: "Login" */ "./views/Login.vue");

// import Register from "./views/Register.vue";
const Register = () =>
  import(/* webpackChunkName: "Register" */ "./views/Register.vue");

// import UserValidation from "./views/UserValidation.vue";
const UserValidation = () =>
  import(/* webpackChunkName: "UserValidation" */ "./views/UserValidation.vue");

// import PasswordReset from "./views/PasswordReset.vue";
const PasswordReset = () =>
  import(/* webpackChunkName: "PasswordReset" */ "./views/PasswordReset.vue");

// import Dashboard from "./views/Dashboard.vue";
// import UserProfile from "./views/UserProfile.vue";
const Dashboard = () =>
  import(/* webpackChunkName: "Dashboard" */ "./views/Dashboard.vue");
const UserProfile = () =>
  import(/* webpackChunkName: "UserProfile" */ "./views/UserProfile.vue");

// import Question from "./views/Question.vue";
// import QuestionEditThemes from "./views/QuestionEditThemes.vue";
// import QuestionEditDetails from "./views/QuestionEditDetails.vue";
// import QuestionEditDocuments from "./views/QuestionEditDocuments.vue";
const Question = () =>
  import(/* webpackChunkName: "Question" */ "./views/Question.vue");
const QuestionEditThemes = () =>
  import(/* webpackChunkName: "Question" */ "./views/QuestionEditThemes.vue");
const QuestionEditDetails = () =>
  import(/* webpackChunkName: "Question" */ "./views/QuestionEditDetails.vue");
const QuestionEditDocuments = () =>
  import(
    /* webpackChunkName: "Question" */ "./views/QuestionEditDocuments.vue"
  );

const NewsletterConfirm = () =>
  import(
    /* webpackChunkName: "NewsletterConfirm" */ "./views/NewsletterConfirm.vue"
  );

Vue.use(VueRouter);

const routes = [
  // Subscription
  {
    path: "/subscription*",
    name: "Subscription",
    component: Subscription,
    // component: () => import(/* webpackChunkName: "Subscription" */ "./views/Subscription.vue"),
    props: true,
    meta: {
      authenticated: true,
    },
  },

  // Home – Registration 1 – create user account
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      title: "Inscription",
    },
  },

  // Registration 2 – email validation
  {
    path: "/user/:userID/validate/:validationToken",
    name: "UserValidation",
    // component: UserValidation,
    component: () =>
      import(
        /* webpackChunkName: "UserValidation" */ "./views/UserValidation.vue"
      ),
  },

  // Registration 3 - complete user profile
  {
    path: "/register",
    name: "register",
    // component: Register,
    component: () =>
      import(/* webpackChunkName: "Register" */ "./views/Register.vue"),
  },

  // Password reset
  {
    path: "/password-reset/:passwordResetToken?",
    name: "passwordReset",
    // component: PasswordReset,
    component: () =>
      import(
        /* webpackChunkName: "PasswordReset" */ "./views/PasswordReset.vue"
      ),
  },

  // Login page
  {
    path: "/login",
    name: "login",
    // component: Login,
    component: () =>
      import(/* webpackChunkName: "Login" */ "./views/Login.vue"),
  },

  // User Dashboard – questions lists
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    // component: () => import(/* webpackChunkName: "Dashboard" */ "./views/Dashboard.vue"),
    meta: {
      authenticated: true,
    },
  },

  // User Profile (optional email update confirmation)
  {
    path: "/user/:userProfileID/profile*",
    name: "userProfile",
    component: UserProfile,
    // component: () => import(/* webpackChunkName: "UserProfile" */ "./views/UserProfile.vue"),
    props: true,
    meta: {
      authenticated: true,
    },
  },

  // display question
  {
    path: "/question/:questionID",
    name: "question",
    // component: Question,
    component: () =>
      import(/* webpackChunkName: "Question" */ "./views/Question.vue"),
    props: true,
    meta: {
      authenticated: true,
    },
  },

  // edit/new question : themes
  {
    path: "/edit-question-themes/:questionID",
    name: "questionEditThemes",
    // component: QuestionEditThemes,
    component: () =>
      import(
        /* webpackChunkName: "QuestionEditThemes" */ "./views/QuestionEditThemes.vue"
      ),
    props: true,
    meta: {
      authenticated: true,
    },
  },

  // edit/new question : details
  {
    path: "/edit-question-details/:questionID",
    name: "questionEditDetails",
    // component: QuestionEditDetails,
    component: () =>
      import(
        /* webpackChunkName: "QuestionEditDetails" */ "./views/QuestionEditDetails.vue"
      ),
    props: true,
    meta: {
      authenticated: true,
    },
  },

  // edit/new question : documents
  {
    path: "/edit-question-documents/:questionID",
    name: "questionEditDocuments",
    // component: QuestionEditDocuments,
    component: () =>
      import(
        /* webpackChunkName: "QuestionEditDocuments" */ "./views/QuestionEditDocuments.vue"
      ),
    props: true,
    meta: {
      authenticated: true,
    },
  },

  // Newsletter

  // Confirm Subscribtion
  {
    path: "/newsletter/confirm-subscription/:validationToken",
    name: "NewsletterConfirmSubscription",

    // component: NewsletterConfirm,
    component: () =>
      import(
        /* webpackChunkName: "NewsletterConfirm" */ "./views/NewsletterConfirm.vue"
      ),

    props: true,
  },

  // Confirm Unsubscription
  {
    path: "/newsletter/confirm-unsubscription/:validationToken",
    name: "NewsletterConfirmUnsubscription",

    // component: NewsletterConfirm,
    component: () =>
      import(
        /* webpackChunkName: "NewsletterConfirm" */ "./views/NewsletterConfirm.vue"
      ),

    props: true,
  },

  // NOTE: code splitting
  // {
  // 	path: "/about",
  // 	name: "about",
  // 	// route level code-splitting
  // 	// this generates a separate chunk (about.[hash].js) for this route
  // 	// which is lazy-loaded when the route is visited.
  // 	component: () =>
  // 		import(/* webpackChunkName: "about" */ "../views/About.vue")
  // }
];

// Router config
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// Route gards: loader
router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start();
  }
  next();
});

router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
